/**
 * @author Aleynikov Boris <aleynikov.boris@gmail.com>.
 */

'use strict';

const
    CLIENT_TYPE = 'Web',
    METRIC1 = '',
    METRIC2 = '',
    METRIC3 = 'Web',
    MAC     = '11:11:11:11:11:11';


module.exports = {
    clientType: done => done(false, CLIENT_TYPE),
    metric1: done => done(false, METRIC1),
    metric2: done => done(false, METRIC2),
    metric3: done => done(false, METRIC3),
    network: {
        mac: done => done(false, MAC)
    }
};
